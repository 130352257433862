// @ts-check
/** @typedef {import("./lib/utils/types").Atlantic} Atlantic  */

//
// Backwards compatible version of the ad code.
// In 2017, we did a major rewrite
// (https://github.com/theatlantic/ads.js/pull/1),
// and this provides support for the old way of doing things.
// This is still used by Ollie, legacy sponsored pages,
// and some static toolkit pages.
//
import "./lib/gpt-events";
import "promise-polyfill/src/polyfill";
import Controller from "./lib/controller";
import { initAPI, getControllerInstance } from "./lib/api";

initAPI();

/**
 * @global
 * @type {Atlantic}
 */
window.Atlantic = window.Atlantic || {};

/** @type {Atlantic} */
window.Atlantic.Ad = {};
window.Atlantic.Ad.Controller = Controller;

let options = (function () {
  try {
    const script = document.querySelector("script[type='gpt-config']");
    return (script && JSON.parse(script.innerHTML)) || {};
  } catch (e) {
    console.error("Cannot parse GPT config.");
  }
})();

// Matches new way of passing user data
if (window.Atlantic.User && window.Atlantic.User.entitlements) {
  options.globals.user = window.Atlantic.User.entitlements;
}

window.__ads.pageview.push(options);

// store controller on the Atlantic global
window.Atlantic.Ad.controller = getControllerInstance();